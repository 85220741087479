import React, { useState, useEffect } from "react";
import { EditBookingPage } from "../edit-booking-page/EditBookingPage";
import { updateProfile } from "../../services/profileService";
import { EditLinkedCalendar } from "../edit-linked-calendar/EditLinkedCalendar";
import { EditAvailability } from "../edit-availability/EditAvailability";
import * as routes from "../../../constants/routes";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import styles from "./EditProfileForm.module.scss";

export const STATUS = {
  IDLE: "IDLE",
  SUBMITTED: "SUBMITTED",
  SUBMITTING: "SUBMITTING",
  COMPLETED: "COMPLETED",
};

export const EditProfileForm = ({ section, profile, onValidityChange }) => {
  const [isValid, setIsValid] = useState(true);
  const [bookingPage, setBookingPage] = useState(profile.bookingPage);
  const [linkedCalendar, setLinkedCalendar] = useState(profile.linkedCalendar);
  const [availability, setAvailiability] = useState(profile.availability);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const handleValidityChange = (isSectionValid) => {
    if (!isValid && isSectionValid) {
      setStatus(STATUS.IDLE);
    }
    setIsValid(isSectionValid);
    onValidityChange(isSectionValid);
  };
  const handleValidationErrors = (validationErrors) => {
    setErrors(validationErrors);
  };
  const handleBookingPageChange = (bookingPage) => setBookingPage(bookingPage);
  const handleLinkedCalendarChange = (calendar) => setLinkedCalendar(calendar);
  const handleAvailabilityChange = (availability) =>
    setAvailiability(availability);

  async function handleSubmit(event) {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    if (isValid) {
      try {
        const profileToSave = {
          ...profile,
          bookingPage,
          linkedCalendar,
          availability,
        };
        await updateProfile(profileToSave);
        setTimeout(() => setStatus(STATUS.COMPLETED));
      } catch (e) {
        setSaveError(e);
      }
    } else {
      setTimeout(() => setStatus(STATUS.SUBMITTED));
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (successOpen) setSuccessOpen(false);
    if (errorOpen) setErrorOpen(false);
  };

  useEffect(() => {
    switch (status) {
      case STATUS.COMPLETED:
        setErrorOpen(false);
        setSuccessOpen(true);
        break;
      case STATUS.SUBMITTING:
        setErrorOpen(false);
        setSuccessOpen(false);
        break;
      case STATUS.SUBMITTED:
        setSuccessOpen(false);
        setErrorOpen(true);
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (!successOpen) {
      setStatus(STATUS.IDLE);
    }
  }, [successOpen]);

  if (saveError) throw saveError;

  return (
    <div id="edit-profile-form">
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          elevation={6}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your booking profile has been saved.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          elevation={6}
          severity="error"
          sx={{ width: "100%" }}
        >
          Oops! We couldn't save your changes:
          <ul>
            {Object.keys(errors).map((key) => {
              return <li key={key}>{errors[key]}</li>;
            })}
          </ul>
        </Alert>
      </Snackbar>
      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        spacing={1}
      >
        {(!section || section === routes.editProfileSection.GENERAL) && (
          <EditBookingPage
            bookingPage={bookingPage}
            status={status}
            onValidityChange={handleValidityChange}
            sendValidationErrors={handleValidationErrors}
            onChange={handleBookingPageChange}
          ></EditBookingPage>
        )}
        {section === routes.editProfileSection.CALENDARS && (
          <EditLinkedCalendar
            calendar={linkedCalendar}
            status={status}
            onValidityChange={handleValidityChange}
            sendValidationErrors={handleValidationErrors}
            onChange={handleLinkedCalendarChange}
          ></EditLinkedCalendar>
        )}
        {section === routes.editProfileSection.AVAILABILITY && (
          <EditAvailability
            availability={availability}
            onChange={handleAvailabilityChange}
          ></EditAvailability>
        )}

        <Button
          component="label"
          variant="contained"
          disabled={status === STATUS.SUBMITTING}
        >
          Save all changes
          <input hidden type="submit" />
        </Button>
      </Stack>
    </div>
  );
};
