import React, { useContext } from "react";
import { Outlet, useNavigate, NavLink, Navigate } from "react-router-dom";
import * as routes from "../../constants/routes";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DashboardIcon from "@mui/icons-material/Dashboard";
import theme from "../../theme";
import { authContext } from "../authContext";

function DashboardContent() {
  const auth = useContext(authContext);
  const navigate = useNavigate();
  const goToDashboard = () =>
    navigate({ pathname: `/${routes.dashboardPath}` });

  return auth ? (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={goToDashboard}>
              <DashboardIcon />
            </IconButton>
            <Stack direction="row">
              <Button
                component={NavLink}
                color="inherit"
                to={`/${routes.dashboardPath}`}
                style={({ isActive }) =>
                  isActive
                    ? {
                        borderBottom: "solid",
                        borderRadius: 0,
                      }
                    : undefined
                }
                size="large"
                disableRipple
              >
                Dashboard
              </Button>
              <Button
                component={NavLink}
                color="inherit"
                to={`/${routes.bookingsPath}`}
                style={({ isActive }) =>
                  isActive
                    ? {
                        borderBottom: "solid",
                        borderRadius: 0,
                      }
                    : undefined
                }
                size="large"
                disableRipple
              >
                Bookings
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  ) : (
    <Navigate to={routes.loginPath} />
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
