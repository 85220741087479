import { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import "dayjs/locale/pl";
import { bookSlot } from "../services/bookingService";
import { profileIdMap } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const BookingForm = ({ slot, profileId, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [place, setPlace] = useState("");
  const [notes, setNotes] = useState("");
  const phonePrefix = "+48";

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await bookSlot({
        firstName,
        lastName,
        email,
        phone,
        place,
        notes,
        startDate: slot.day,
        profileId: profileIdMap[profileId],
        startHour: slot.day.hour()
      });
      onSubmit({ success: response });
    } catch (error) {
      onSubmit({ error });
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card sx={style}>
        <CardHeader
          title={slot?.day.locale("pl").format("dddd, D MMMM YYYY, HH:mm")}
          action={
            <IconButton aria-label="close" onClick={onSubmit}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel required htmlFor="first-name">
              Imię
            </InputLabel>
            <Input
              required
              id="first-name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel required htmlFor="last-name">
              Nazwisko
            </InputLabel>
            <Input
              required
              id="last-name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel required htmlFor="email">
              Email
            </InputLabel>
            <Input
              required
              id="email"
              type="email"
              inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$" }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel required htmlFor="phone">
              Telefon
            </InputLabel>
            <Input
              required
              id="phone"
              type="tel"
              inputProps={{
                maxLength: 9,
                minLength: 9,
                pattern: "[0-9]*",
              }}
              startAdornment={
                <InputAdornment position="start">{phonePrefix}</InputAdornment>
              }
              onChange={(e) => setPhone(`${phonePrefix}${e.target.value}`)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel required htmlFor="place">
              Placówka
            </InputLabel>
            <Input
              required
              id="place"
              onChange={(e) => setPlace(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <TextField
              id="notes"
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={3}
              placeholder="Uwagi"
              variant="standard"
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained">
            Rezerwuj
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
