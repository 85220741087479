import React, { useState, useEffect } from "react";
import { STATUS } from "../edit-profile-form/EditProfileForm";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import styles from "./EditBookingPage.module.scss";

export const EditBookingPage = ({
  bookingPage,
  status,
  onChange,
  onValidityChange,
  sendValidationErrors,
}) => {
  const [touched, setTouched] = useState({});

  // Derived state
  const errors = getErrors(bookingPage);
  const isValid = Object.keys(errors).length === 0;

  const renderBookingPage = (bookingPage) => (
    <Stack spacing={1}>
      <div>
        <label htmlFor="title">Booking page title</label>
        <TextField
          id="title"
          fullWidth
          value={bookingPage.title}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            !!((touched.title || status === STATUS.SUBMITTED) && errors.title)
          }
          helperText={
            (touched.title || status === STATUS.SUBMITTED) && errors.title
          }
        />
      </div>
      <div>
        <label htmlFor="link">Booking page link</label>
        <TextField
          id="link"
          fullWidth
          value={bookingPage.link}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            !!((touched.link || status === STATUS.SUBMITTED) && errors.link)
          }
          helperText={
            (touched.link || status === STATUS.SUBMITTED) && errors.link
          }
        />
      </div>

      <div>
        <label htmlFor="intro">Booking page intro (Optional)</label>
        <TextField
          id="intro"
          multiline
          fullWidth
          maxRows={4}
          value={bookingPage.intro}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
    </Stack>
  );

  useEffect(() => onValidityChange(isValid), [isValid]);
  useEffect(() => {
    if (status === STATUS.SUBMITTED) {
      sendValidationErrors({ ...errors });
    }
  }, [status]);

  function handleChange(event) {
    onChange({
      ...bookingPage,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setTouched((cur) => {
      return { ...cur, [event.target.id]: true };
    });
  }

  function getErrors(bookingPage) {
    const result = {};
    if (!bookingPage.title) result.title = "The booking page needs a title.";
    if (!bookingPage.link || bookingPage.link.length < 3)
      result.link = "Booking page link must have at least 3 letters.";
    return result;
  }

  return <>{renderBookingPage(bookingPage)}</>;
};
