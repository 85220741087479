import React, { useState, useEffect } from "react";
import { STATUS } from "../edit-profile-form/EditProfileForm";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "./EditLinkedCalendar.module.scss";

export const EditLinkedCalendar = ({
  calendar,
  status,
  onChange,
  onValidityChange,
  sendValidationErrors,
}) => {
  const [touched, setTouched] = useState({});

  // Derived state
  const errors = getErrors(calendar);
  const isValid = Object.keys(errors).length === 0;

  const renderCalendar = (calendar) => (
    <Stack spacing={1}>
      <div>
        <label htmlFor="availabilityCalendar">
          We'll check your availability on:
        </label>
        <TextField
          id="availabilityCalendar"
          fullWidth
          value={calendar.availabilityCalendar}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            !!(
              (touched.availabilityCalendar || status === STATUS.SUBMITTED) &&
              errors.availabilityCalendar
            )
          }
          helperText={
            (touched.availabilityCalendar || status === STATUS.SUBMITTED) &&
            errors.availabilityCalendar
          }
        />
      </div>
      <div>
        <label htmlFor="eventsCalendar">
          And create new booking events on:
        </label>
        <TextField
          id="eventsCalendar"
          fullWidth
          value={calendar.eventsCalendar}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            !!(
              (touched.eventsCalendar || status === STATUS.SUBMITTED) &&
              errors.eventsCalendar
            )
          }
          helperText={
            (touched.eventsCalendar || status === STATUS.SUBMITTED) &&
            errors.eventsCalendar
          }
        />
      </div>
    </Stack>
  );

  useEffect(() => onValidityChange(isValid), [isValid]);
  useEffect(() => {
    if (status === STATUS.SUBMITTED) {
      sendValidationErrors({ ...errors });
    }
  }, [status]);

  function handleChange(event) {
    onChange({
      ...calendar,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setTouched((cur) => {
      return { ...cur, [event.target.id]: true };
    });
  }

  function getErrors(calendar) {
    const result = {};
    if (!calendar.availabilityCalendar)
      result.availabilityCalendar =
        "Please choose at least one writable calendar.";
    if (!calendar.eventsCalendar)
      result.eventsCalendar = "Please choose at least one writable calendar.";
    return result;
  }

  return (
    <>
      <Typography component="h1" variant="h6" color="inherit">
        Linked calendar
      </Typography>
      {renderCalendar(calendar)}
    </>
  );
};
