import React from "react";
import { BookingPageListItem } from "./BookingPageListItem";
import { Spinner } from "../../common/Spinner";
import useFetch from "../../services/useFetch";
import { getBookingPageListUrl } from "../../constants/apiUrls";
import Typography from "@mui/material/Typography";
import styles from "./BookingPageList.module.scss";

export const BookingPageList = () => {
  const {
    data: bookingPageList,
    loading,
    error,
  } = useFetch(getBookingPageListUrl);
  const renderItem = (bookingPage) => (
    <BookingPageListItem
      key={bookingPage.profileId}
      bookingPage={bookingPage}
    />
  );

  if (error) throw error;
  if (loading) return <Spinner />;

  return (
    <>
      <Typography component="h1" variant="h6" color="inherit">
        All booking pages
      </Typography>
      <section id="booking-page-list">
        {bookingPageList.map(renderItem)}
      </section>
    </>
  );
};
