import { useState, useRef, useEffect, useContext } from "react";
import { authContext } from "../admin/authContext";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function useFetch(url) {
  const isMounted = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, refetch] = useState({});
  const auth = useContext(authContext);

  useEffect(() => {
    isMounted.current = true;
    async function init() {
      try {
        const response = await fetch(baseUrl + url, {
          headers: { Authorization: auth ? `Bearer ${auth.credential}` : null },
        });
        if (response.ok) {
          const json = await response.json();
          if (isMounted.current) setData(json);
        } else {
          throw response;
        }
      } catch (e) {
        if (isMounted.current) setError(e);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    }
    init();

    return () => {
      isMounted.current = false;
    };
  }, [url, shouldRefetch]);

  return { data, error, loading, refetch };
}
