import { useNavigateSearch } from "../../../services/useNavigateSearch";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import * as routes from "../../../constants/routes";
import styles from "./BookingListItem.module.scss";

export const BookingListItem = ({ booking }) => {
  const locales = "en-US";
  const navigateSearch = useNavigateSearch();
  const goToBookingDetails = () =>
    navigateSearch(
      `/${routes.bookingDetailsPath}`,
      [
        [routes.bookingDetailsIdSearchParam, booking.id],
        [routes.bookingsProfileIdSearchParam, booking.profileId],
      ],
      { canNavigateBack: true }
    );

  return (
    <TableRow hover>
      <TableCell
        component="th"
        scope="row"
        sx={{ cursor: "pointer" }}
        onClick={goToBookingDetails}
      >
        {new Date(booking.startsAtUTC).toLocaleDateString(locales, {
          timeZone: booking.timeZone,
        })}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {new Date(booking.startsAtUTC).toLocaleTimeString(locales, {
          timeZone: booking.timeZone,
        })}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {booking.durationMinutes} minutes
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {booking.title}
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={goToBookingDetails}
          variant="text"
          startIcon={<EventAvailableIcon />}
        >
          Details
        </Button>
      </TableCell>
    </TableRow>
  );
};
